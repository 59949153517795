<template>
  <div class="pb-5">
    <header class="mb-4">
      <TheDashboardHeader />
    </header>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import TheDashboardHeader from '@/components/TheDashboard/TheDashboardHeader'

export default {
  components: {
    TheDashboardHeader,
  },
}
</script>
