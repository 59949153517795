<template>
  <div class="container-fluid bg-header py-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <div class="px-3" style="margin-right: 12px;">
          <router-link class="nav-link" :to="{ name: 'index' }">
            <img width="29" height="30" src="@/assets/images/pictogram.svg">
          </router-link>
        </div>
        <transition name="view" mode="out-in" appear>
          <div v-if="$store.state.publication.data.id">
            <strong>
              <router-link class="text-dark" :to="{ name: 'publications-id', params: { publication: $store.state.publication.data.id }}">
                {{ $store.state.publication.data.name }}
              </router-link>
            </strong>
          </div>
        </transition>
      </div>
      <div class="pe-3">
        <a-dropdown class="nav-link" :trigger="['click']">
          <div class="d-flex align-items-center" style="cursor: pointer;">
            <div class="text-dark me-2">
              {{ $store.state.user.name }}
            </div>
            <div>
              <a-avatar
                icon="user"
                :src="$store.state.user.image.resolutions.default"
                :alt="$store.state.user.name"
              />
            </div>
          </div>
          <template #overlay>
            <a-menu @click="onMenuClick">
              <a-menu-item key="account">
                <UserIcon class="icon text-muted me-2" />Profile
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="logout">
                <ArrowLeftOnRectangleIcon class="icon text-muted me-2" />Sign Out
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftOnRectangleIcon, UserIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    ArrowLeftOnRectangleIcon,
    UserIcon,
  },

  methods: {
    async onMenuClick(e) {
      if (e.key === 'logout') {
        await this.onLogout()
      } else {
        this.$router.push({ name: e.key })
      }
    },
    async onLogout() {
      try {
        await this.$http.delete('/session')
        await this.$store.dispatch('refreshToken')

        this.$router.push({ name: 'login' })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      }
    },
  },
}
</script>

<style scoped>
.bg-header {
  background: linear-gradient(90deg, #ebedee, #fdfbfb);
}
</style>
